<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <div class="col-lg-12">
                <div class="card elevation-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2 col-2">
                                <v-select class="ma-0 pa-0 border-12" v-model="qualityModel" multiple :items="qualityLists" item-value="quality_id" item-text="quality_id" label="QUALITY" 
                                    clearable solo></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select class="ma-0 pa-0 border-12"  v-model="widthModel" :items="widthLists" item-value="width" item-text="width" label="WIDTH" clearable solo></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select class="ma-0 pa-0 border-12"  v-model="thicknessModel" :items="thicknessLists" item-value="thick" item-text="thick" label="THICKNESS" clearable solo></v-select>
                            </div>
                            <div class="col-lg-2 col-1">
                                <v-select class="ma-0 pa-0 border-12" v-model="sign" :items="signs" item-value="id" item-text="id" label="Sign" clearable solo></v-select>
                            </div>
                            <div class="col-lg-2 col-1">
                                <v-text-field
                                    solo
                                    v-model="wgt_used"
                                    label="Wgt Used"
                                    persistent-hint
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details="true"
                                    type="number"
                                ></v-text-field>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-btn class="mt-3 border-12" style="padding: 19px;" color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </div>
                        </div>                                                   
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="card-header text-right"><v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn></div>
                        <v-data-table :headers="resultHeaders" :items="itemLists" class="elevation-1 mytable" page-count="10" :search="searchItem" :loading="loadingDatatable" dense>
                            <template slot="body.append">
                                <tr class="pink--text">
                                    <th class="">Totals</th>
                                    <th class=""></th>
                                    <th class=""></th>
                                    <th class="text-right">{{ formatNumber(sumField('HRC')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('HRP')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('HRPO')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('CRC')) }}</th>
                                </tr>
                            </template>
                            <template v-slot:[`item.HRC`]="{ item }">
                                <span>{{ formatNumber(item.HRC) }}</span>
                            </template>
                            <template v-slot:[`item.HRP`]="{ item }">
                                <span>{{ formatNumber(item.HRP) }}</span>
                            </template>
                            <template v-slot:[`item.HRPO`]="{ item }">
                                <span>{{ formatNumber(item.HRPO) }}</span>
                            </template>
                            <template v-slot:[`item.CRC`]="{ item }">
                                <span>{{ formatNumber(item.CRC) }}</span>
                            </template>
                            <template v-slot:[`item.totalKanan`]="{ item }">
                                <tr class="pink--text">
                                    <th class="text-right">{{ formatNumber(item.HRC + item.HRP + item.HRPO + item.CRC) }}</th>
                                </tr>
                            </template>
                        </v-data-table>                                             
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'HRC and HRP',
                disabled: false,
                href: '#',
                },
                {
                text: 'HRC and HRP Availability',
                disabled: true,
                href: '#',
                },
            ],
            widthModel: '',
            widthLists: [],
            thicknessModel: '',
            thicknessLists: [],
            qualityModel: '',
            qualityLists: [],
            itemLists: [],
            resultHeaders: [
                { text: 'THICK.', value: 'thick', align: 'right', class:"primary--text blue lighten-5" },
                { text: 'WIDTH', value: 'width', align: 'right', class:"primary--text blue lighten-5" },
                { text: 'QUALITY', value: 'quality_id', align: 'right', class:"primary--text blue lighten-5" },
                { text: 'HRC', value: 'HRC', align: 'right', class:"primary--text blue lighten-5" },
                { text: 'HRP', value: 'HRP', align: 'right', class:"primary--text blue lighten-5" },
                { text: 'HRPO', value: 'HRPO', align: 'right', class:"primary--text blue lighten-5" },
                { text: 'CRC', value: 'CRC', align: 'right', class:"primary--text blue lighten-5" },
                { text: '', value: 'totalKanan', align: 'right', class:"primary--text blue lighten-5" }
            ],
            searchItem: '',
            loadingDatatable: false,
            exportToExcelLists: [],
            sign:'',
            signs: [
                {id: '='},
                {id: '<'},
                {id: '>'},
                {id: '<='},
                {id: '>='},
            ],
            wgt_used:'',
        }
    },
    async mounted(){
        
        $('.cardResult').hide()
        this.initialize()

    },
    methods:{
        
        initialize(){
            
            this.$store.dispatch('setOverlay', true)

            axios.get(`${process.env.VUE_APP_URL}/api/sm/CoilAvailability`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.widthLists = res.data.width
                this.thicknessLists = res.data.thickness
                this.qualityLists = res.data.quality

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

            // this.getData()

        },

        async getData(){

            this.itemLists = []
            this.exportToExcelLists = []
            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/sm/CoilAvailability/getDataV2`, { 
                    quality: this.qualityModel ? this.qualityModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    thick: this.thicknessModel ? this.thicknessModel : '',
                    sign: this.sign ? this.sign : '',
                    wgt_used : this.wgt_used ? this.wgt_used : '',
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                $('.cardResult').show()
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                if( res.data.result.length != 0 ) {
                    this.itemLists = res.data.result
                    this.exportToExcelLists = res.data.result
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }
                    

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        sumField(key) {
            // sum data in give key (property)
            return this.itemLists.reduce((a, b) => a + (b[key] || 0), 0)
        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "Coil-Availability-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

    .clr1 {
        background: #d6eeee;
    }

    .clr2 {
        background: #f2d0a9;
    }

    .clr3 {
        background: #ced3dc;
    }

    table, td {
        border: 1px solid black;
    }

</style>